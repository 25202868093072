import {usePage} from "@inertiajs/vue3";

export function hasPermissions(permissions, organizationId = null) {
    const auth = usePage().props.auth;
    const userPermissions = Object.values(auth.permissions);
    let permissionResult = false;

    if (Array.isArray(permissions)) {
        permissionResult = permissions.every(permission => userPermissions.includes(permission));
    } else {
        permissionResult = userPermissions.includes(permissions);
    }
    if (organizationId) {
        permissionResult = permissionResult && auth?.organization?.id == organizationId;
    }
    return permissionResult;
}
